<script setup lang="ts">
const { t } = useI18n();

const items = [
  {
    title: t("block.footerInfo.title_1"),
    subtitle: t("block.footerInfo.subtitle_1"),
  },
  {
    title: t("block.footerInfo.title_2"),
    subtitle: t("block.footerInfo.subtitle_2"),
  },
];
</script>

<template>
  <ul class="flex flex-col gap-5 md:flex-row">
    <li
      v-for="{ title, subtitle } in items"
      :key="title"
      class="p-3 flex flex-col gap-2 rounded-6 flex-1 bg-neutral-500/[0.04]"
    >
      <h3 class="text-white text-[1rem] font-medium leading-normal">
        {{ title }}
      </h3>
      <p class="text-[0.875rem] text-neutral-500">
        {{ subtitle }}
      </p>
    </li>
  </ul>
</template>

<style scoped lang="scss"></style>
